<template>
    <div class="footer">
        <div class="w">
            <div class="footer_layout">
              <div class="footer_left">
                  <p class="footer_test"><span class="span">{{ $t("message.Information") }}</span></p>
                  <p class="footer_test"><span class="span">{{ $t("message.PrivacyPolicy") }}</span></p>
                  <p class="footer_test"><span  class="span">{{ $t("message.CookiePolicy") }}</span></p>
                  <p class="footer_test"><span  class="span">{{ $t("message.termsofuse") }}</span></p>
              </div>
              <div class="footer_right">
                <p class="footer_span">{{ $t("message.ContactUs") }}</p>
              </div>
            </div>
            <div class="footer_pig">
              <div class="pig_left">
                <span>{{ $t("message.AggregationRongTechnology") }}</span>
              </div>
              <div class="pig_right">
                <img src="../assets/img/footer/facebook.jpg" alt="" class="footer_img" id='img_left'>
                <img src="../assets/img/footer/instagram.jpg" alt="" class="footer_img" id='img_left'>
                <img src="../assets/img/footer/tuite.jpg" alt=" " class="footer_img">
              </div>
            </div>
        </div>
    </div>
  </template> 
  
  <script>
  export default {
    name: "",
    props: {},
    components: {},
    data() {
      return {};
    },
    methods: {},
  
    created() {},
  
    mounted() {},
  
    computed: {},
  
    watch: {},
  };
  </script>
  
  <style lang='scss' scoped>
  @import url('../assets/css/footer.css');
  </style>