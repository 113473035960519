import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
    // 首页
  {
    path: '/',
    name: "index",
    component: () => import('@/views/index'),
    meta: {
      title: "JHRON",
    },
  },
    //服务
  {
    path: '/service',
    name: "service",
    component: () => import('@/views/service'),
    meta: {
      title: "服务",
    },
  },
    // 方案
  {
    path: '/case',
    name: "case",
    component: () => import('@/views/case'),
    meta: {
      title: "方案",
    },
  },
  // 我们
  {
    path: '/our',
    name: "our",
    component: () => import('@/views/our'),
    meta: {
      title: "我们",
    },
  },
    // 分支
  {
    path: '/branch',
    name: "branch",
    component: () => import('@/views/branch'),
    meta: {
      title: "分支",
    },
  },
    // 新闻
  {
    path: '/news',
    name: "news",
    component: () => import('@/views/news'),
    meta: {
      title: "新闻",
    },
  },
  {
    path: '/details',
    name: "details",
    component: () => import('@/views/details'),
    meta: {
      title: "新闻详情",
    },
  },
  {
    path: '/InstitutionalBranches',
    name: "InstitutionalBranches",
    component: () => import('@/views/InstitutionalBranches'),
    meta: {
      title: "机构分支",
    },
  },
]

const router = new VueRouter({
  mode: 'history', //hash history
  base: process.env.BASE_URL,
  routes
})


const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

router.beforeEach((to, from, next) => {
  // console.log(to);
  document.title = to.meta.title; //动态改变浏览器标题
  next()
  // if (to.path === "/login") {
  //   //当进入的就是登陆页面或者注册页面时，不做操作
  //   next();
  // } else {
  //   if (localStorage.getItem('token')) {
  //     //同过登陆成功时存储在本地用户名，来判断用户是否成功登陆
  //     next();
  //   } else {
  //     next("/login");
  //   }
  // }
});

export default router
