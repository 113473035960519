import store from '../store'
import {
    mapGetters,
    mapState
} from 'vuex';
export default {
    data() {
        return {

        }
    },
    created() { },

    mounted() { },

    methods: {
        toPath(e) { //页面跳转
            this.$router.push(e)
        },
        backLinks(e) { //外部链接跳转
            window.open(e);
        }
    },

    computed: {
        ...mapState({

        }),
    },

    watch: {

    }
}