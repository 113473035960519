
import service from "./service";
import cookies from 'vue-cookies';


//  url 请求路径
//  params 参数
//  sToken 是否需要token 默认需要

let token = { 'X-Authorization': cookies.get('token') }

export default {
  get(url, params, isToken = true) {

    const config = {
      method: 'get',
      url: url,
    }
    if (params) {
      config.params = params;
    }
    if (isToken) {
      config = { ...config, ...token }
    }
    return service(config);
  },
  geta(url, params, isToken = true) {

    const config = {
      method: 'get',
      url: url,
    }
    if (params) {
      config.url = `${url}/${params}`;
    }
    if (isToken) {
      config = { ...config, ...token }
    }
    return service(config);
  },
  post(url, params, isToken = true) {

    const config = {
      method: 'post',
      url: url,
    }
    if (params) {
      config.data = params;
    }
    if (isToken) {
      config = { ...config, ...token }
    }
    return service(config);
  }

};

