module.exports = {
  message: {
    service: '服务',
    programme:'方案',
    our:'我们',
    branch:"分支",
    news:'新闻',
    Information:'企业信息',
    PrivacyPolicy:'隐私政策',
    CookiePolicy:'cookie政策',
    termsofuse:'使用条款',
    ContactUs:'联系我们',
    AggregationRongTechnology:'聚合荣科技'
  },
  placeholder: {
      enter: '请输入您喜欢的品牌'
  },
  brands: {
      nike: '耐克',
      adi: '阿迪达斯',
      nb: '新百伦',
      ln: '李宁'
  }
}