<template>
  <div class="header">
    <div class="w" id="banxin">
      <div @click="Jump('index')">
        <img src="../assets/img/basics/logo.png" alt="" class="Logo_img">
      </div>
      <div class="nav">
        <div class="navnavigation_left">
          <p class="navigation_test" @click="Jump('service')">{{ $t("message.service") }}</p>
          <p class="navigation_test" @click="Jump('programme')"> {{ $t('message.programme') }}</p>
          <p class="navigation_test" @click="Jump('our')">{{ $t('message.our') }}</p>
        </div>
        <div class="navnavigation_right">
          <p class="navigation_test">{{ $t('message.branch') }}</p>
          <p class="navigation_test" @click="Jump('news')">{{ $t('message.news') }}</p>
          <p><img src="../assets/img/basics/english.png" alt="" class="navnavigation_right_img" @click="show()"></p>
        </div>
      </div>
      <div class="English" v-show="flag">
        <p class="English_test" @click="handleSetLanguage('en')">English</p>
        <p class="china_test" @click="handleSetLanguage('zh')">简体中文</p>
      </div>
    </div>
  </div>
</template> 
  
<script>
export default {
  name: "",
  props: {},
  components: {},
  data() {
    return {
      flag: false,
    };
  },
  methods: {
    Jump(content) {
      console.log(content)
      switch (content) {
        case 'service':
          this.$router.push({
            path: '/service',
          })
          break;
        case 'programme':
          this.$router.push({
            path: '/case',
          })
          break;
        case 'our':
          this.$router.push({
            path: '/our',
          })
          break;
        case 'news':
          this.$router.push({
            path: '/news',
          })
          break;
        case 'index':
          this.$router.push({
            path: '/',
          })
        break;
      }
    },
    show() {
      this.flag = !this.flag
    },
    handleSetLanguage(data) {
      localStorage.setItem("key", data)
      this.$router.go(0); 
    }

  },
 
  created() {
    localStorage.setItem("key", 'zh')
  },

  mounted() { },

  computed: {

  },

  watch: {
    '$route' (to, from) {
    
    //  console.log(to)
  }
  },
};
</script>
  
<style lang='scss' scoped>
@import url('../assets/css/header.css');
</style>