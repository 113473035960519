<template>
  <div id="app">
    <Header></Header>
    <router-view />
    <Footer></Footer>
  </div>
</template>
<script>
import Header from './components/Header.vue'
import Footer from './components/footer.vue'
export default {
  name: "",
  props: {},
  components: { Header,Footer },
  data() {
    return {};
  },
  methods: {},

  created() { },

  mounted() { },

  computed: {},

  watch: {},
};
</script>
<style lang="scss">
#app{
  width: 100%;
}


//页面内容
</style>