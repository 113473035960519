// 移动端适配

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import api from './http/api'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import * as echarts from 'echarts';
import VueCookies from 'vue-cookies';
import md5 from 'js-md5'
import { } from '@/utils/permissions/permissions.js'
import mixin from '@/components/mixin.js'
import 'lib-flexible/flexible.js'
// import buyRobot from '@/components/buyRobot/buyRobot'
import './assets/css/common.css'
Vue.use(ElementUI);
Vue.use(VueCookies)

Vue.mixin(mixin)


Vue.config.productionTip = false
Vue.prototype.$api = api;


Vue.prototype.$echarts = echarts;
Vue.prototype.$md5 = md5
Vue.prototype.$http = ''
// 语言插件

import VueI18n from 'vue-i18n'
Vue.use(VueI18n);
/* eslint-disable no-new */
import messages from '@/multi/zh.js'

const i18n = new VueI18n({
  // 3 ? true : false
  locale: localStorage.getItem("key"),  // 语言标识
  messages: {
      'zh': require('@/multi/zh.js'),
      'en': require('@/multi/en.js')
  }

});

// Vue.component('buyRobot', buyRobot)

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
