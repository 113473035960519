module.exports = {
  message: {
    service: 'service',
    programme:'programme',
    our:'our',
    branch:"branch",
    news:'news',
    Information:'Information',
    PrivacyPolicy:'PrivacyPolicy',
    CookiePolicy:'CookiePolicy',
    termsofuse:'termsofuse',
    ContactUs:'ContactUs',
    AggregationRongTechnology:'AggregationRongTechnology'
  },
  placeholder: {
      enter: 'Please type in your favorite brand'
  },
  brands: {
      nike: 'Nike',
      adi: 'Adidas',
      nb: 'New Banlance',
      ln: 'LI Ning'
  }
}